import Carousel from 'react-bootstrap/Carousel';
import {CRASHER_DAY, CRASHER_HOUR, Event} from "../../../Helper";
import React from "react";
import CrasherHour from "./includes/CrasherHour";
import CrasherDay from "./includes/CrasherDay";

function showHour() {
    Event.emit('show_banner_hour');
}

function showDay() {
    Event.emit('show_banner_day');
}

function BannerCarousal() {

    return (
        <Carousel>
            <Carousel.Item onClick={showHour}>
                <img className={"d-block w-100"} style={{borderRadius: '4px 4px 0px 0px'}} src={CRASHER_HOUR} alt="Player of the Hour"/>
                <div className={'d-block w-100 bg-image-custom text-white'} style={{borderRadius: '0px 0px 4px 4px', fontSize: '0.8em', paddingLeft: '5px'}}><CrasherDay/></div>
            </Carousel.Item>
            <Carousel.Item onClick={showDay}>
                <img style={{borderRadius: '4px 4px 0px 0px'}} className="d-block w-100" src={CRASHER_DAY} alt="Crasher"/>
                <div className={'d-block w-100 bg-image-custom text-white'} style={{borderRadius: '0px 0px 4px 4px', fontSize: '0.8em', paddingLeft: '5px'}}><CrasherHour/></div>
            </Carousel.Item>
        </Carousel>
    );
}

export default BannerCarousal;